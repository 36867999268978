<template>
    <LFeatureGroup v-if="isTrackerLayerVisible" ref="trackerLayer">
        <component :is="markerClusterComponent" ref="trackerLayerCluster">
            <template v-if="!simpleMarkersEnabledConditionally">
                <template v-for="tracker in trackersToDisplay.cluster">
                    <component
                        :is="getTrackerComponent(tracker)"
                        :key="tracker.id"
                        :asset="tracker"
                        :is-selected="
                            activeTracker && activeTracker.id === tracker.id
                        "
                        :interactive="interactive"
                        @click="$emit('markerClicked', tracker.id)"
                    />
                </template>
            </template>
        </component>

        <template v-if="!simpleMarkersEnabledConditionally">
            <template v-for="tracker in trackersToDisplay.noCluster">
                <component
                    :is="getTrackerComponent(tracker)"
                    :key="tracker.id"
                    :asset="tracker"
                    :is-selected="
                        activeTracker && activeTracker.id === tracker.id
                    "
                    :interactive="interactive"
                    @click="$emit('markerClicked', tracker.id)"
                />
            </template>
        </template>
    </LFeatureGroup>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { debounce } from 'lodash'
import { marker, layerGroup } from 'leaflet'
import { LFeatureGroup } from 'vue2-leaflet'

import AirQualityMarker from './AirQualityMarker'
import AssetMarkerCluster from './AssetMarkerCluster'
import DynamicBinMarker from './asset-types/dynamic-bin/DynamicBinMarker'
import GenericMarker from './GenericMarker'
import LevelAssetMarker from './asset-types/level/LevelAssetMarker'
import MachineMarker from './MachineMarker'
import ParkingSpaceMarker from './ParkingSpaceMarker'
import SbbAssetMarkerCluster from './asset-types/sbb-bin/SbbAssetMarkerCluster'
import SbbBinMarker from './asset-types/sbb-bin/SbbBinMarker'
import TruckMarker from './TruckMarker'

const markerMapping = {
    'air-quality': AirQualityMarker,
    'dynamic-bin': DynamicBinMarker,
    'dynamic-bin-collection': DynamicBinMarker,
    'parking-space': ParkingSpaceMarker,
    'sbb-bin': SbbBinMarker,
    level: LevelAssetMarker,
    machine: MachineMarker,
    truck: TruckMarker,
}

const addDefaultClusterGroup = debounce(function() {
    const { mapObject } = this.$refs.trackerLayerCluster.$refs.instance
    const newLayerGroup = layerGroup(
        this.trackersToDisplay.cluster.map(this.createDefaultMarker)
    )
    if (this.defaultClusterGroup) {
        mapObject.clearLayers()
    }
    this.defaultClusterGroup = newLayerGroup
    mapObject.addLayer(this.defaultClusterGroup)
}, 100)

const addDefaultNoClusterGroup = debounce(function() {
    const { mapObject } = this.$refs.trackerLayer
    if (this.defaultNoClusterGroup) {
        this.defaultNoClusterGroup.clearLayers()
        this.trackersToDisplay.noCluster.forEach(tracker => {
            this.defaultNoClusterGroup.addLayer(
                this.createDefaultMarker(tracker)
            )
        })
    } else {
        this.defaultNoClusterGroup = layerGroup(
            this.trackersToDisplay.noCluster.map(this.createDefaultMarker)
        )
        mapObject.addLayer(this.defaultNoClusterGroup)
    }
}, 100)

export default {
    name: 'TrackerLayer',
    components: {
        LFeatureGroup,
    },
    props: {
        // Allows to exlude markers from clustering.
        // Provide Array of either Tracker IDs or Tracker Objects (with property 'id').
        excludedFromClustering: {
            type: Array,
            default: () => [],
        },
        interactive: {
            type: Boolean,
            default: true,
        },
        onlyIds: {
            type: Array,
            default: null,
        },
        trackers: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            defaultClusterGroup: null,
            defaultNoClusterGroup: null,
            isTrackerLayerVisible: false,
        }
    },
    computed: {
        ...mapState('sharing', ['activeSharedTracker']),
        ...mapState('tracker', [
            'activeTrackerOnMap',
            'assetTypes',
            'filterParams',
            'filteredTrackers',
            'shouldAdjustMapOnFilterChange',
        ]),
        ...mapGetters('map', ['simpleMarkersEnabledConditionally']),
        activeTracker() {
            return this.activeSharedTracker ?? this.activeTrackerOnMap
        },
        trackersToDisplay() {
            const trackersToCluster = []
            const trackersNotToCluster = []

            for (const tracker of this.filteredTrackers ?? this.trackers) {
                if (this.excludedIDs.includes(tracker.id)) {
                    trackersNotToCluster.push(tracker)
                } else {
                    trackersToCluster.push(tracker)
                }
            }

            return {
                cluster: trackersToCluster,
                noCluster: trackersNotToCluster,
            }
        },
        excludedIDs() {
            return this.excludedFromClustering
                .filter(Boolean)
                .map(item => (item > 0 ? item : item.id))
        },
        markerClusterComponent() {
            return process.env.VUE_APP_ENVIRONMENT_NAME === 'sbb'
                ? SbbAssetMarkerCluster
                : AssetMarkerCluster
        },
        trackerComponents() {
            return this.assetTypes.reduce((acc, { type }) => {
                acc[type] = markerMapping[type] || GenericMarker
                return acc
            }, {})
        },
    },
    watch: {
        activeTracker: {
            immediate: true,
            handler(tracker) {
                if (tracker) {
                    this.$emit('alignMap', [
                        tracker.asset_details
                            ? [
                                  tracker.asset_details.position.latitude,
                                  tracker.asset_details.position.longitude,
                              ]
                            : [tracker.position.lat, tracker.position.lng],
                    ])
                }
            },
        },
        filterParams() {
            if (this.shouldAdjustMapOnFilterChange) {
                this.$emit(
                    'alignMap',
                    this.trackers.map(tracker => [
                        tracker.asset_details.position.latitude,
                        tracker.asset_details.position.longitude,
                    ])
                )
            }
        },
    },
    mounted() {
        // By setting the Leaflet FeatureGroup to invisible at first, all the nested components (markers) add their
        // layers to this FeatureGroup without it being rendered in the DOM. Then by setting the FeatureGroup
        // visible in the next tick, all the markers get added to the map at once.
        // This seems way faster. Otherwise every marker gets rendered independently and the map updates hundreds of times.
        this.$nextTick(() => {
            setTimeout(() => {
                this.isTrackerLayerVisible = true
                this.$watch(
                    () => [
                        this.simpleMarkersEnabledConditionally,
                        this.trackersToDisplay.cluster,
                        this.trackersToDisplay.noCluster,
                        this.interactive,
                        this.$route,
                    ],
                    (
                        [isSimpleMarkersEnabledConditionally],
                        [wasSimpleMarkersEnabledConditionally] = []
                    ) => {
                        if (isSimpleMarkersEnabledConditionally) {
                            addDefaultClusterGroup.call(this)
                            addDefaultNoClusterGroup.call(this)
                        } else if (wasSimpleMarkersEnabledConditionally) {
                            this.removeDefaultClusterGroup()
                            this.removeDefaultNoClusterGroup()
                        }
                    },
                    { immediate: true }
                )
            })
        })
    },
    beforeDestroy() {
        this.removeDefaultClusterGroup()
        this.removeDefaultNoClusterGroup()
    },
    methods: {
        ...mapMutations('tracker', ['updateAsset']),
        createDefaultMarker(tracker) {
            return marker(
                tracker.asset_details?.position
                    ? [
                          tracker.asset_details.position.latitude,
                          tracker.asset_details.position.longitude,
                      ]
                    : [tracker.position?.lat, tracker.position?.lng],
                {
                    draggable:
                        tracker.asset_details?.static &&
                        this.$route.name === 'editAsset' &&
                        this.$route.params.id == tracker.id,
                    interactive: this.interactive,
                }
            )
                .bindTooltip(tracker.asset_details?.name ?? tracker.name)
                .on('click', () => {
                    this.$emit('markerClicked', tracker.id)
                })
                .on('dragend', ({ target }) => {
                    this.updateAsset({
                        id: tracker.asset_details?.id ?? tracker.id,
                        position: {
                            latitude: target._latlng.lat,
                            longitude: target._latlng.lng,
                        },
                    })
                })
        },
        getTrackerComponent(tracker) {
            const assetType = tracker.asset_details?.asset_type_type
            return this.trackerComponents[assetType] ?? GenericMarker
        },
        removeDefaultClusterGroup() {
            if (this.defaultClusterGroup) {
                addDefaultClusterGroup.cancel()
                this.$refs.trackerLayerCluster.$refs.instance.mapObject.clearLayers()
                this.defaultClusterGroup = null
            }
        },
        removeDefaultNoClusterGroup() {
            if (this.defaultNoClusterGroup) {
                addDefaultNoClusterGroup.cancel()
                this.defaultNoClusterGroup?.removeFrom(
                    this.$refs.trackerLayer.mapObject
                )
                this.defaultNoClusterGroup = null
            }
        },
    },
}
</script>
